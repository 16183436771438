import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HttpClientService {
    constructor(private httpClient: HttpClient) {}

    public post(url: string, data?: any, options?: any): Observable<any> {
        // let token = this.storage.getToken();
        const httpOptions = {
            // headers: { 'Content-Type': 'multipart/form-data' },
        };
        if (options) {
            Object.assign(httpOptions, options);
        }
        return this.httpClient.post(url, data, httpOptions);
    }

    public put(url: string, data?: any, options?: any): Observable<any> {
        // let token = this.storage.getToken();
        const httpOptions = {
            // headers: new HttpHeaders({ authorization: 'Bearer ' + token.access_token })
        };
        if (options) {
            Object.assign(httpOptions, options);
        }
        return this.httpClient.put(url, data, httpOptions);
    }

    public delete(url: string, options?: any): Observable<any> {
        // let token = this.storage.getToken();
        const httpOptions = {
            // headers: new HttpHeaders({ authorization: 'Bearer ' + token.access_token })
        };
        if (options) {
            Object.assign(httpOptions, options);
        }
        return this.httpClient.delete(url, httpOptions);
    }

    public get(url: string, options?: any): Observable<any> {
        const httpOptions = {
            // headers: new HttpHeaders({ authorization: 'Bearer ' + token.access_token })
        };
        if (options) {
            Object.assign(httpOptions, options);
        }
        return this.httpClient.get(url, httpOptions);
    }

    public getHttpClient() {
        return this.httpClient;
    }
}
