import { OverlayModule } from '@angular/cdk/overlay';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeSlExtra from '@angular/common/locales/extra/sl';
import localeSl from '@angular/common/locales/sl';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MfCaptchaModule } from '@meddev/fe-shared';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMatomoRouterModule } from '@ngx-matomo/router';
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import { RecaptchaModule, RecaptchaV3Module } from 'ng-recaptcha';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { environment } from './../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PblVersionComponent } from './modules/public/pbl-version/pbl-version.component';
import { PublicComponent } from './modules/public/public.component';
import { LayoutModule } from './theme/layouts/layout.module';
import { ThemeComponent } from './theme/theme.component';
import { GlobalErrorHandler } from './_services/error-handler.service';
import { StorageService } from './_services/storage.service';
import { WindowRef } from './_services/window-ref.service';
import { BaseState } from './_state/base/base.state';

registerLocaleData(localeSl, 'sl', localeSlExtra);

@NgModule({
    declarations: [AppComponent, ThemeComponent, PublicComponent, PblVersionComponent],
    imports: [
        NgbModule,
        LayoutModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        ToastContainerModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-center',
        }),
        NgxsModule.forRoot([BaseState], {
            developmentMode: !environment.production,
        }),
        NgxsRouterPluginModule.forRoot(),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        NgxsLoggerPluginModule.forRoot(),
        OverlayModule,
        RecaptchaModule,
        RecaptchaV3Module,
        NgxMatomoRouterModule,
        NgxMatomoTrackerModule.forRoot({
            disabled: !environment.production,
            siteId: '22',
            trackerUrl: 'https://data.medifit.si/',
        }),
        MfCaptchaModule.forRoot({
            language: 'sl',
            siteKey: '6Ld843IcAAAAAGBGrFVX-xuAb80d60uhNngd4gTT',
        }),
    ],
    providers: [
        StorageService,
        WindowRef,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
