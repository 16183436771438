<div class="mf-loading-wrapper">
    <div class="mf-loading d-flex align-items-center">
        <div class="mf-loading__text h5 mb-0" i18n>Prosimo počakajte...</div>

        <div class="lds-ring pl-2 mr-2">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</div>
